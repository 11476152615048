export interface AssesmentHeaderModel {
    clientName?: string;
    dateOfBirth?: Date;
    insuranceNumber?: string;
    dateOfSession?: string;
    TherapistName?: string;
}

export enum currentLivingSituation {

    RENTING = "RENTING",
    OWN = "OWN",
    HOUSE = "HOUSE",
    APT = "APT",
    MOBILEHOME = "MOBILE_HOME"

}

export interface GeneralInforModel {
    pcpReferal?: string;
    npi?: string;
    gender?: genralInfoGender;
    age?: number;
    consumerDob?: string;
    consumerphone?: string;
    ethnicity?: generalInforEthnicity;
    other?: string;
    emergancyContactName?: string;
    phone?: string;
    individualParticipants?: string;
    insuranceBesideBCBS?: {
        noOtherInusrnace?: boolean;
        nameOfInsurance?: string;
    };
    otherTherapyService?: {
        noService?: boolean;
        nameOfService?: string;
    };
}

export enum genralInfoGender {
    male = "male",
    female = "female",
}

export enum generalInforEthnicity {
    Hispanic = "Hispanic",
    Caucasian = "Caucasian",
    AfricanAmerican = "AfricanAmerican",
    AsianAmerican = "AsianAmerican",
    Other = 'Other'
}


export interface ChiefComplaintModel {
    precipitatingProblem?: string;
    HistoryofIllness?: string;
    familySocialHistory?: string;
    shortTermGoal?: string;
    longTermGoal?: string;
}

export interface CurrentServicesModel {
    mentalHealth?: {
        nonReported?: boolean;
        explanation?: string;
    },
    substanceAbuse?: {
        nonReported?: boolean
        explanation?: string;
    },
    medical?: {
        nonReported?: boolean
        explanation?: string;
    },
    vocational?: {
        nonReported?: boolean
        explanation?: string;
    },
    developmental?: {
        nonReported?: boolean
        explanation?: string;
    },
    other?: {
        nonReported?: boolean
        explanation?: string;
    }
    treatmentAttitudeOverTime?: string;
    previousRecoveryFactors?: string;
    notes?: string;
}

export interface ReligiousCulturalModel {
    christian?: boolean;
    doesntKnow?: boolean;
    otherReligion?: {
        isOtherReligion?: boolean;
        otherReligionName?: string;
    },
    english?: boolean;
    spanish?: boolean;
    otherlanguage?: {
        isOtherLanguage?: boolean;
        otherLanguageName?: string;
    }
}

export interface LeisureActivityModel {
    description?: string;
    frequency?: string;
    barriers?: string;
}

export interface HouseHoldMemberModel {
    firstName?: string;
    lastName?: string;
    age?: number;
    gender?: string;
    consumerRelation?: string;
    relationDescription?: relationDescription;
}

export enum relationDescription {
    POOR = "POOR",
    FAIR = "FAIR",
    GOOD = "GOOD",
    GREAT = "GREAT",
}

export interface HistoryofAbuseModel {

    physicalAbuse?: {
        nonReported?: boolean;
        explanation?: string;
    },
    sexualAbuse?: {
        nonReported?: boolean;
        explanation?: string;
    },
    emotionalAbuse?: {
        nonReported?: boolean;
        explanation?: string;
    },
    historyofNeglect?: {
        nonReported?: boolean;
        explanation?: string;
    },
    otherTrauma?: {
        nonReported?: boolean;
        explanation?: string;
    },
    currentInvolvementCPSDSSAPS?: {
        nonReported?: boolean;
        explanation?: string;
    },
    historyInvolvementCPSDSSAPS?: {
        nonReported?: boolean;
        explanation?: string;
    },
}

export interface PSAServiceHistoryModel {
    type?: string,
    providerName?: string,
    datesofService?: number,
    outcome?: string,
}

export interface HistoryofPsychiatricDiagnosesModel {
    diagnosis?: string;
    providerName?: string;
}

export interface HistoryofSymptomsModel {
    impulsiveness?: string;
    irritability?: string;
    changeinHealthStatus?: string;
    InvoluntaryCommitment?: string;
    angerManagement?: string;
    legalInvolvement?: string;
    appetiteDisturbance?: string;
    impairedJudgment?: string;
    substanceAbuse?: string;
    truancy?: string;
    sleepDisturbance?: string;
    traumaticBrainInjury?: string;
    behaviorProblems?: string;
    missedWork?: string;
    delusions?: string;
    memoryLoss?: string;
    moodSwings?: string;
    otherHC?: string;
    other?: string;
}

export interface CurrentMedicationsModel {
    Name?: string;
    Dosage?: string;
    Prescriber?: string;
    Effects?: string;
}
export interface MedicalHistoryTableModel {
    illness?: string;
    treatmentandResponse?: string;
    barrierstoRecovery?: string;
}

export interface MedicalHistoryModel {
    medicalHistoryTableSchema?: MedicalHistoryTableModel[];
    baselineMammogram?: string;
    strengths?: string;
    needs?: string;
    checkIfNone?: boolean;
}


export interface HealthNotesModel {
    notes?: string;
    allergies?: {
        isReported?: boolean;
        explanation?: string;
    };
    enuresisOrEncopresis?: {
        isReported?: boolean;
        explanation?: string;
    };
    visionComplications?: {
        isReported?: boolean;
        explanation?: string;
    };
    hearingComplications?: {
        isReported?: boolean;
        explanation?: string;
    };
    dentalComplications?: {
        isReported?: boolean;
        explanation?: string;
    };
    dietRestrictions?: {
        isReported?: boolean;
        explanation?: string;
    };
    seizures?: {
        isReported?: boolean;
        explanation?: string;
    };
    somaticComplaints?: {
        isReported?: boolean;
        explanation?: string;
    };
    heartBloodPressure?: {
        isReported?: boolean;
        explanation?: string;
    };
    diabetes?: {
        isReported?: boolean;
        explanation?: string;
    };
    hxofEatingDisorder?: {
        isReported?: boolean;
        explanation?: string;
    };
    problemsSleeping?: {
        isReported?: boolean;
        explanation?: string;
        goingtoSleep?: boolean;
        stayingAsleep?: boolean;
        Nightmares?: boolean;
    };
    historyofHeadInjury?: {
        isReported?: boolean;
        explanation?: string;
    };
    dateofLastPhysical?: string;
}

export interface LegalHistoryModel {
    ifNoCurrent?: boolean;
    currentlegalCharges?: {
        explanation?: string;
    };
    probationOfficer?: {
        name?: string;
    };
    currentlyonProbation?: {
        name?: string;
    };
    historyofIncarcerations?: {
        name?: string;
        additionalInformation?: boolean;
    };
    historyoflegalInvolvement?: {
        name?: string;
    };
    familylegalInvolvement?: {
        name?: string;
    };
    specificTermsofProbation?: {
        name?: string;
    };
    legalImpairment?: {
        name?: LegalImpairment;
    };
}

export enum LegalImpairment {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

export interface SubstanceModel {
    ageAtFirstUse?: string;
    ageAtRegularUse?: string;
    currentFrequencyAverageUse?: string;
    methodOfAdministration?: string;
    lastDateUsed?: string;
    primarySecondaryOrTertiary?: string;
}

export interface SARelatedWithdrawalSymptomsModel {
    past?: string;
    present?: string;
}

export interface SubstanceInfoModel {
    // substanceAbuseHistoryReported: boolean;
    noSAHistoryReported?: boolean;
    nicotine?: SubstanceModel;
    alcohol?: SubstanceModel;
    marijuana?: SubstanceModel;
    cocaineCrack?: SubstanceModel;
    amphetamines?: SubstanceModel;
    hallucinogens?: SubstanceModel;
    ecstasyOther?: SubstanceModel;
    inhalants?: SubstanceModel;
    heroin?: SubstanceModel;
    barbiturates?: SubstanceModel;
    other?: SubstanceModel;
    substanceMeans?: string;
    mortivationForUse?: string;
    useReductionInterest?: string;
    pastAbstinence?: string;
    problemUsageCaused?: string;
    additionalHistory?: addtionalHistory;
    chargesHistory?: string;
    sARelatedWithdrawalSymptoms?: SARelatedWithdrawalSymptomsModel;
    signsOfTolerance?: string;
    isRecommended?: boolean;
    recommend?: string;
}

export enum addtionalHistory {
    DWI = "DWI",
    BLACKOUTS = "BLACKOUTS",
    ABSENTEEISM = "ABSENTEEISM",
    SEIZURES = "SEIZURES",
    JOBLOSS = "JOBLOSS",
    IVDRUGUSE = "IVDRUGUSE",
}

export interface AsamDimensionsModel {
    intoxicationWithdrawal?: level;
    medicalConditions?: level;
    psychiatricCoMorbidities?: level;
    mortivationforTreatment?: level;
    relapsePrevention?: level;
    recoveryEviornment?: level;
}

export enum level {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

export interface EmploymentVocationalModel {
    currentlyEmployed?: boolean;
    currentPosition?: string;
    employedSince?: string;
    numJobsLastFiveYears?: number;
    ssdiClaimStatus?: string;
    ssdiEligible?: boolean;
    employSatisfaction?: string;
    employmentHistory?: string;
    barriersChallenges?: string;
    identifiedStrengths?: string;
    identifiedNeeds?: string;
    checkIfNA?: boolean;
}

export interface IndependentLivingModel {
    assistanceWithSkills?: {
        feedingSelf?: boolean;
        financialAssistance?: boolean;
        applyingForBenefits?: boolean;
        mealPreparation?: boolean;
        legalAssistance?: boolean;
        academicEnrollment?: boolean;
        groceryShopping?: boolean;
        attendingCourtMandatedEvents?: boolean;
        vocationalAssistance?: boolean;
        nutrition?: boolean;
        accessingSpecializedServices?: boolean;
        basicHygiene?: boolean;
        accessingSupportSystems?: boolean;
        toiletTraining?: boolean;
        transportation?: boolean;
        otherSkills?: string;
    }
}

export interface MentalStatusExamModel {
    appearanceDress?: {
        neat?: boolean;
        relaxed?: boolean;
        disheveled?: boolean;
        eccentric?: boolean;
        ageAppropriate?: boolean;
        other?: string;
        hygiene?: {
            normal?: boolean;
            bodyOdor?: boolean;
            badBreath?: boolean;
        };
    };
    behaviour?: {
        passive?: boolean;
        defensive?: boolean;
        guarded?: boolean;
        hostile?: boolean;
        attentive?: boolean;
        cooperative?: boolean;
        demanding?: boolean;
        oppositional?: boolean;
        selfDestructive?: boolean;
        preoccupied?: boolean;
        eyeContact?: {
            intense?: boolean;
            appropriate?: boolean;
            sporadic?: boolean;
            avoidant?: boolean;
        };
    };
    motor?: {
        relaxed?: boolean;
        catatonic?: boolean;
        posturing?: boolean;
        restless?: boolean;
        tremors?: boolean;
        other?: string;
        pacing?: boolean;
        tics?: boolean;
        threatening?: boolean;
        mannerisms?: boolean;
        appearsSedated?: boolean;
        psychomotorRet?: boolean;
    };
    language?: {
        rate?: {
            normal?: boolean;
            pressured?: boolean;
            slow?: boolean;
        };
        quality?: {
            normal?: boolean;
            apraxic?: boolean;
            dysarthric?: boolean;
            paraphasia?: boolean;
            clanging?: boolean;
            echolalia?: boolean;
            incoherent?: boolean;
            neologisms?: boolean;
        };
        volume?: {
            normal?: boolean;
            loud?: boolean;
            soft?: boolean;
        };
    };
    mood?: {
        normal?: boolean;
        euphoric?: boolean;
        elevated?: boolean;
        angry?: boolean;
        depress?: boolean;
        anxious?: boolean;
        irritable?: boolean;
        other?: string;
    };
    affect?: {
        broad?: boolean;
        blunted?: boolean;
        reactive?: boolean;
        flat?: boolean;
        congruent?: boolean;
        labile?: boolean;
        restricted?: boolean;
        other?: string;
        inappropriate?: boolean;
    };
    thoughtFormation?: {
        logical?: boolean;
        concrete?: boolean;
        obsessive?: boolean;
        unexplainedDizziness?: boolean;
        illogical?: boolean;
        relevant?: boolean;
        blocking?: boolean;
        sequential?: boolean;
        irrelevant?: boolean;
        circumstantial?: boolean;
        indecisive?: boolean;
        distractible?: boolean;
        tangential?: boolean;
        goalDirected?: boolean;
        other?: string;
        flightofIdeas?: boolean;
        looseAssociations?: boolean;
    };
    thoughtContent?: {
        delusions?: boolean;
        somatic?: boolean;
        suicidal?: boolean;
        separationLossDwelling?: boolean;
        guilt?: boolean;
        homicidal?: boolean;
        phobias?: boolean;
        obsessions?: boolean;
        grandiose?: boolean;
        suspicious?: boolean;
        hopelessness?: boolean;
        futureOriented?: boolean;
        hallucinations?: boolean;
    };
    orientation?: {
        person?: boolean;
        place?: boolean;
        time?: boolean;
        situation?: boolean;
    };
    estimateIntellect?: {
        average?: boolean;
        upAverage?: boolean;
        downAverage?: boolean;
        borderline?: boolean;
        mentalRetardation?: boolean;
        knownDisability?: string;
    };
    memory?: {
        adequate?: boolean;
        impaired?: boolean;
        recent?: boolean;
        remote?: boolean;
        selective?: boolean;
    };
    perception?: {
        auditoryHallucinations?: boolean;
        visualHallucinations?: boolean;
        depersonalization?: boolean;
        traumaticFlashbacks?: boolean;
        ideasofReference?: boolean;
    };
    judgment?: {
        good?: boolean;
        fair?: boolean;
        impaired?: string;
        poor?: boolean;
    };
    impulseControl?: {
        fair?: boolean;
        poor?: boolean;
        severe?: boolean;
        adequate?: boolean;
        wNL?: boolean;
        mildlydistractible?: boolean;
        moderately?: boolean;
    };
}



export interface SuicideRiskPotentialModel {

    noRiskfactorsIdentified?: boolean;
    suicideAttempts?: {
        actionMethod?: string,
        Ideation?: string,
        plan?: string,
    },
    homicideAttempts?: {
        actionMethod?: string,
        Ideation?: string,
        plan?: string,
    },
    historyOfImpulsiveBehaviors?: boolean,
    safeEnviornment?: boolean,
    crueltytoAnimalsHistory?: boolean,
    fireSetting?: boolean,
    otherRiskFactors?: string,
    lethalityAssessment?: lethalityAssessment,
    addtionalComments?: string
}

export enum lethalityAssessment {
    NONE = "NONE",
    LOW = "LOW",
    MODERATE = "MODERATE",
    HIGH = "HIGH",
    IMMINENT = "IMMINENT",
}

export interface SummaryofNeedSchemaModel {

    financial?: boolean,
    housing?: boolean,
    spiritual?: boolean,
    sexuality?: boolean,
    safetyCrisis?: boolean,
    transportation?: boolean,
    employment?: boolean,
    medicalHealth?: boolean,
    substanceUse?: boolean,
    parentingSkills?: boolean,
    physicalNeeds?: boolean,
    adaptive?: boolean,
    educationalVocational?: boolean,
    emotionalPsychological?: boolean,
    legalCourtInvolvement?: boolean,
    familyAttachment?: boolean,
    culturalImmigration?: boolean,
    socialSkillsRecreational?: boolean,
    communicationLanguage?: boolean,
    behaviorManagement?: boolean,
    problemSolvingSkills?: boolean,
    developmentalHistory?: boolean,
}

export interface RecoveryHistoryandEnvironmentModel {

    isPreviousMentalHealthTreatment?: boolean,
    isPreviousSubstanceAbuseTreatment?: boolean,
    recoveryEnvironment?: recoveryEnvironment,
}
export enum recoveryEnvironment {
    POOR = "POOR",
    FAIR = "FAIR",
    SUPPORTIVE = "SUPPORTIVE",

}

export enum mortivationEngageInServices {

    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

export interface CurrentImpairmentssModel {
    moodDisturbance?: ImpairmentLevel;
    anxiety?: ImpairmentLevel;
    psychosis?: ImpairmentLevel;
    thinkingCognitionMemory?: ImpairmentLevel;
    impulsiveRecklessAggressive?: ImpairmentLevel;
    activitiesOfDailyLiving?: ImpairmentLevel;
    weightLossAssocWithEatingDO?: ImpairmentLevel;
    medicalPhysicalConditions?: ImpairmentLevel;
    substanceAbuseDependence?: ImpairmentLevel;
    schoolJobPerformance?: ImpairmentLevel;
    socialMaritalFamilyProblems?: ImpairmentLevel;
    legal?: ImpairmentLevel;
}

export enum ImpairmentLevel {
    NONE = "NONE",
    MILD = "MILD",
    MODERATE = "MODERATE",
    SEVERE = "SEVERE",
    NA = "NA"
}


export enum SeverityScore {
    NR = "NR",
    NotPresent = "NotPresent",
    VeryMild = "VeryMild",
    Mild = "Mild",
    Moderate = "Moderate",
    ModeratelySevere = "ModeratelySevere",
    Severe = "Severe",
    ExtremelySevere = "ExtremelySevere"
}

export interface psychiatricSymptomModel {
    anxiety?: SeverityScore;
    tension?: SeverityScore;
    depressiveMood?: SeverityScore;
    helplessnessHopelessness?: SeverityScore;
    guiltFeelings?: SeverityScore;
    somaticConcern?: SeverityScore;
    hostility?: SeverityScore;
    suspiciousness?: SeverityScore;
    uncooperativeness?: SeverityScore;
    distractibility?: SeverityScore;
    elatedMood?: SeverityScore;
    motorHyperactivity?: SeverityScore;
    disorientation?: SeverityScore;
    disorganizedSpeech?: SeverityScore;
    grandioseStatements?: SeverityScore;
    unusualIdeas?: SeverityScore;
    hallucinatoryStatements?: SeverityScore;
    hallucinatoryBehavior?: SeverityScore;
    socialWithdrawal?: SeverityScore;
    bluntedAffect?: SeverityScore;
    motorRetardation?: SeverityScore;
    mannerismsPosturing?: SeverityScore;
    lossOfFunction?: SeverityScore;
}

export interface findingModel {
    code?: string;
    description?: string;
}

export interface nonReportedOrDescribe {
    nonReported?: boolean;
    describePlan?: string;
}

export interface SummeryOfFindingModel {
    summeryOfFindingNarrative?: string;
    I1?: findingModel;
    // I2?: findingModel;
    II1?: findingModel;
    // II2?: findingModel;
    III?: findingModel;
    IV?: findingModel;
    V?: findingModel;
    advanceDirectives?: nonReportedOrDescribe;

}

export interface PeaopleModel {
    name1?: {
        name?: string;
        phone?: string;
    },
    name2?: {
        name?: string;
        phone?: string;
    },
    name3?: {
        name?: string;
        phone?: string;
    },
}

export interface ClientSaftyPlanModel {

    people?: PeaopleModel;
    professionals?: {
        clinicianName?: string;
        clinicianPhone?: string;
        docotorName?: string;
        docotorPhone?: string;
        careServiceAddress?: string;
        enviornmentSafe?: {
            1?: string;
            2?: string
        },
        agreement?: {
            agree?: string;
            clientSignature?: string;
            clientDate?: string;
            therapistSignature?: string;
            therapistDate?: string;
        }
    }
}

export interface SignatureDetailsModel {
    comprehensiveCliniCalAssesment?: {
        nameOfClinician?: string;
        date?: string;
    },
    // clientAcknowledgingParticipation?:{
    //     clientSignature?:any;
    //     date?:string;
    // }
}

export interface AceScoreModel {
    humiliateOrPhysicallyHurt?: boolean;
    pushGrabSlap?: boolean;
    intercourse?: boolean;
    familySupport?: boolean;
    noOneToProtectYou?: boolean;
    parentSeperateDivorce?: boolean;
    hitWithSomthingHard?: boolean;
    threatenedWithGunOrKnife?: boolean;
    liveWithDrugUser?: boolean;
    isHouseHoldMemberattemptSuicide?: boolean;
    isHouseHOldMemberGoToPrison?: boolean;
    aceScore?: number;
}


export interface Diflevel {
    notDifficult?: boolean;
    someWhatDifficult?: boolean;
    veryDifficult?: boolean;
    extremlyDifficult?: boolean;
}
export interface score {
    notAtAll?: boolean;
    severalDays?: boolean;
    moreThanHalfTheDays?: boolean;
    NearlyEveryDay?: boolean;
}
export interface ColumnScore {
    column1?: number;
    column2?: number;
    column3?: number;
    column4?: number;
    total?: number;
}

export interface GAD7AnxietyModel {
    feelingNervous?: score;
    cantControllWorrying?: score;
    worryingMuch?: score;
    troubleRelaxing?: score;
    beingRestless?: score;
    easilyAnnoyed?: score;
    feelingAfraid?: score;
    difficultLevel?: Diflevel;
    columnCal?: ColumnScore;
}


export interface PatientHealthQuestionaireModel {
    littleInteres?: score;
    feelingDown?: score;
    troubleFalling?: score;
    feelingTired?: score;
    poorAppetite?: score;
    feelingBad?: score;
    troubleConcentrating?: score;
    speakingSlowly?: score;
    thoughtsDead?: score;
    columnCal?: ColumnScore;
    difficultLevel?: Diflevel;
}

export enum likertScale {

    STRONGLYDISAGREE = "STRONGLYDISAGREE",
    DISAGREE = "DISAGREE",
    AGREE = "AGREE",
    NOTSURE = "NOTSURE",
    STRONGLYAGREE = "STRONGLYAGREE",
}
export interface RecoveryAssesmentScaleModel {
    desireToSucceed?: string;
    ownPlanForWellness?: string;
    lifeGoals?: string;
    beliefInMeetingGoals?: string;
    senseOfPurpose?: string;
    othersCaringmySelf?: string;
    fearlessLiving?: string;
    handleLifeSituations?: string;
    selfLove?: string;
    peopleLikeMe?: string;
    ideaWhoWantToBe?: string;
    somethingGoodHappen?: string;
    hopefulness?: string;
    continueInterests?: string;
    mentalIllnessCoping?: string;
    symptomInterference?: string;
    symptomDuration?: string;
    helpSeekingAwareness?: string;
    willingnessToAskForHelp?: string;
    askingHelpWhenNeed?: string;
    stressHandling?: string;
    peaopleCanCounton?: string;
    otherPeopleBelive?: string;
    importanceOfFriendship?: string;
}
