import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Card, Col, Input, Row, Table } from "reactstrap";
import { ClinicalAssetment } from "src/models/clinicalAssesment/clinicalAssesment";
import { lethalityAssessment } from "src/models/clinicalAssesment/clinicalAssesmentSubmodel";
import { CommonService } from "src/services/CommonService";

const FormSeven = (props: any) => {
    const { formId, assesmenData,getAssesmentByClientId, customActiveTab } = props;
    const [formData, setFormData] = useState<ClinicalAssetment>({
        leisureActivity: [{}],
        houseHoldMember: [{}],
        pSAServiceHistory: [{}],
        historyofPsychiatricDiagnoses: [{}],
        currentModications: [{}],
        medicalHistory: { medicalHistoryTableSchema: [{}] }
    })

    const [edit, setEdit] = useState<boolean>(true)
    useEffect(() => {
        if (assesmenData) {
            const updateAssesment: ClinicalAssetment = {
                stepCount: 7,
                _id: formId,
                leisureActivity: [{}],
                houseHoldMember: [{}],
                pSAServiceHistory: [{}],
                historyofPsychiatricDiagnoses: [{}],
                currentModications: [{}],
                medicalHistory: { medicalHistoryTableSchema: [] },
                mentalStatusExam: assesmenData.mentalStatusExam,
                suicideRiskPotential: assesmenData.suicideRiskPotential,
            }
            setFormData(updateAssesment);
        }
    }, [assesmenData,customActiveTab]);

    const updateAssesmentDetails = () => {
        const updateAssesment: ClinicalAssetment = {
            stepCount: 7,
            _id: formId,
            leisureActivity: [],
            houseHoldMember: [{}],
            pSAServiceHistory: [{}],
            historyofPsychiatricDiagnoses: [{}],
            currentModications: [{}],
            medicalHistory: { medicalHistoryTableSchema: [] },
            mentalStatusExam: formData.mentalStatusExam,
            suicideRiskPotential: formData.suicideRiskPotential,

        }

        CommonService.updateClinicalAssesment(updateAssesment).then(res => {
            if (res.success) {
                toast.success(res.success, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
                getAssesmentByClientId();
                props.nextStep();
            } else {
                toast.error(res.error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }
        });
    };

    // useEffect(() => {
    //     const GetEditvalue = localStorage.getItem("isEdit");
    //     console.log("GetEditvalue", GetEditvalue);
    //     if (GetEditvalue !== null) {
    //         const isEdit = GetEditvalue === "true";
    //         console.log("isEdit", isEdit);
    //         setEdit(isEdit);
    //     }
    // }, [props.currentStep]);

    return (
        <>
            <Card className="BorderRadiusLeftRight m-0 pb-1 pt-1">
                {/* <Row className="d-flex align-items-center">
                    <Col className="d-flex justify-content-end">
                        {!edit ? (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                Edit
                            </button>
                        ) : (
                            <button className="btn btn-primary mb-3" onClick={handleEdit}>
                                View
                            </button>
                        )}
                    </Col>
                </Row> */}
                <div className="text-end">
                    <button
                        className="btn btn-primary mb-2"
                        type="button"
                        onClick={() => {
                            updateAssesmentDetails();
                        }}
                    >
                        {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                    </button>
                </div>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                            <h5 className="fw-500" style={{ margin: "auto" }}>Mental Status Exam</h5>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ textAlign: "left", minWidth: "930px" }}>
                                    <tbody style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ width: "30%", padding: "0.2rem" }}>APPEARANCE / DRESS</td>
                                            <td style={{ width: "70%", padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="appearanceDress"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.neat}
                                                                    onClick={() => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, appearanceDress: {
                                                                                neat: true
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Neat</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="appearanceDress"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.relaxed}
                                                                    onClick={() => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, appearanceDress: {
                                                                                relaxed: true
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Relaxed</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="appearanceDress"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.disheveled}
                                                                    onClick={() => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, appearanceDress: {
                                                                                disheveled: true
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Disheveled</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="appearanceDress"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.eccentric}
                                                                    onClick={() => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, appearanceDress: {
                                                                                eccentric: true
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Eccentric</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="appearanceDress"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.ageAppropriate}
                                                                    onClick={() => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, appearanceDress: {
                                                                                ageAppropriate: true
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Age appropriate</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Hygiene :</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="hygiene"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.hygiene?.normal}
                                                                    onClick={() => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, appearanceDress: {
                                                                                ...formData.mentalStatusExam?.appearanceDress, hygiene: { normal: true }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Normal</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="hygiene"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.hygiene?.bodyOdor}
                                                                    onClick={() => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, appearanceDress: {
                                                                                ...formData.mentalStatusExam?.appearanceDress, hygiene: { bodyOdor: true }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Body Odor</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger", }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="hygiene"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.appearanceDress?.hygiene?.badBreath}
                                                                    onClick={() => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, appearanceDress: {
                                                                                ...formData.mentalStatusExam?.appearanceDress, hygiene: { badBreath: true }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Bad Breath</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Other :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="otherDress"
                                                                                readOnly={!edit}
                                                                                value={formData.mentalStatusExam?.appearanceDress?.other || ""}
                                                                                onChange={e => setFormData(prevState => ({
                                                                                    ...prevState,
                                                                                    mentalStatusExam: {
                                                                                        ...prevState.mentalStatusExam,
                                                                                        appearanceDress: {
                                                                                            ...prevState.mentalStatusExam?.appearanceDress,
                                                                                            other: e.target.value
                                                                                        }
                                                                                    }
                                                                                }))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>BEHAVIOR</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.passive}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, behaviour: { passive: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Passive</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.guarded}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, behaviour: { guarded: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Guarded</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.attentive}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, behaviour: { attentive: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Attentive</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.demanding}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, behaviour: { demanding: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Demanding</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.preoccupied}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, behaviour: { preoccupied: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Preoccupied</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.defensive}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, behaviour: { defensive: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Defensive</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.hostile}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, behaviour: { hostile: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Hostile</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.cooperative}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, behaviour: { cooperative: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Cooperative</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.oppositional}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, behaviour: { oppositional: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Oppositional</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.selfDestructive}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, behaviour: { selfDestructive: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Self-Destructive</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Eye Contact :</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.eyeContact?.intense}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, behaviour: { ...formData.mentalStatusExam?.behaviour, eyeContact: { intense: e.target.checked } }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Intense</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.eyeContact?.appropriate}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, behaviour: { ...formData.mentalStatusExam?.behaviour, eyeContact: { appropriate: e.target.checked } }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Appropriate</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.eyeContact?.sporadic}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, behaviour: { ...formData.mentalStatusExam?.behaviour, eyeContact: { sporadic: e.target.checked } }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Sporadic</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.mentalStatusExam?.behaviour?.eyeContact?.avoidant}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, behaviour: { ...formData.mentalStatusExam?.behaviour, eyeContact: { avoidant: e.target.checked } }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Avoidant</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>MOTOR</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.relaxed}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { relaxed: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Relaxed</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.restless}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { restless: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Restless</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.pacing}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { pacing: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Pacing</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.threatening}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { threatening: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Threatening</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.appearsSedated}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { appearsSedated: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Appears sedated</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.catatonic}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { catatonic: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Catatonic</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.tremors}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { tremors: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Tremors</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.tics}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { tics: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Tics</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.mannerisms}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { mannerisms: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Mannerisms</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.psychomotorRet}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { psychomotorRet: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Psychomotor Ret.</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="motor"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.motor?.posturing}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, motor: { posturing: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Posturing</label>
                                                            </td>
                                                            <td colSpan={4}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Other :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="otherMotor"
                                                                                readOnly={!edit}
                                                                                value={formData.mentalStatusExam?.motor?.other || ""}
                                                                                onChange={e => setFormData(prevState => ({
                                                                                    ...prevState,
                                                                                    mentalStatusExam: {
                                                                                        ...prevState.mentalStatusExam,
                                                                                        motor: {
                                                                                            ...prevState.mentalStatusExam?.motor,
                                                                                            other: e.target.value
                                                                                        }
                                                                                    }
                                                                                }))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" rowSpan={3} style={{ width: "20%", padding: "0.2rem" }}>LANGUAGE</td>
                                            <td className="fw-500" style={{ width: "10%", padding: "0.2rem", textAlign: "right" }}>Rate :</td>
                                            <td className="fw-500" style={{ width: "70%", padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="rate"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.rate?.normal}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, rate: { normal: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Normal</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="rate"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.rate?.pressured}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, rate: { pressured: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Pressured</label>
                                                            </td>
                                                            <td colSpan={3}>
                                                                <input style={{ fontSize: "larger", }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="rate"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.rate?.slow}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, rate: { slow: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Slow</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" style={{ width: "10%", padding: "0.2rem", textAlign: "right" }}>Quality :</td>
                                            <td className="fw-500" style={{ width: "70%", padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.normal}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, quality: { normal: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Normal</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.apraxic}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, quality: { apraxic: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Apraxic</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.dysarthric}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, quality: { dysarthric: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Dysarthric</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger", }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.paraphasia}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, quality: { paraphasia: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Paraphasia</label>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.clanging}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, quality: { clanging: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Clanging</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.echolalia}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, quality: { echolalia: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Echolalia</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.incoherent}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, quality: { incoherent: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Incoherent</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger", }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="quality"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.quality?.neologisms}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, quality: { neologisms: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Neologisms</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" style={{ width: "10%", padding: "0.2rem", textAlign: "right" }}>Volume:</td>
                                            <td className="fw-500" style={{ width: "70%", padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="volume"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.volume?.normal}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, volume: { normal: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Normal</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="volume"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.volume?.loud}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, volume: { loud: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Loud</label>
                                                            </td>
                                                            <td colSpan={3}>
                                                                <input style={{ fontSize: "larger", }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="volume"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.language?.volume?.soft}
                                                                    onChange={e => setFormData({
                                                                        ...formData, mentalStatusExam: {
                                                                            ...formData.mentalStatusExam, language: {
                                                                                ...formData.mentalStatusExam?.language, volume: { soft: e.target.checked }
                                                                            }
                                                                        }
                                                                    })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Soft</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>MOOD</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.normal}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, mood: { normal: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Normal</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.elevated}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, mood: { elevated: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Elevated</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.depress}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, mood: { depress: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Depressed</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.anxious}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, mood: { anxious: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Anxious</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.irritable}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, mood: { irritable: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Irritable</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.euphoric}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, mood: { euphoric: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Euphoric</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="mood"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.mood?.angry}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, mood: { angry: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Angry</label>
                                                            </td>
                                                            <td colSpan={3}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Other :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="otherMood"
                                                                                readOnly={!edit}
                                                                                value={formData.mentalStatusExam?.mood?.other}
                                                                                onChange={e => setFormData(prevState => ({
                                                                                    ...prevState,
                                                                                    mentalStatusExam: {
                                                                                        ...prevState.mentalStatusExam,
                                                                                        mood: {
                                                                                            ...prevState.mentalStatusExam?.mood,
                                                                                            other: e.target.value
                                                                                        }
                                                                                    }
                                                                                }))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>AFFECT</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.broad}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, affect: { broad: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Broad</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.reactive}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, affect: { reactive: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Reactive</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.congruent}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, affect: { congruent: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Congruent</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.restricted}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, affect: { restricted: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Restricted</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.inappropriate}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, affect: { inappropriate: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Inappropriate</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.blunted}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, affect: { blunted: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Blunted</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.flat}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, affect: { flat: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Flat</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="affect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.affect?.labile}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, affect: { labile: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Labile</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Other :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="otherAffect"
                                                                                readOnly={!edit}
                                                                                value={formData.mentalStatusExam?.affect?.other || ""}
                                                                                onChange={e => setFormData({
                                                                                    ...formData,
                                                                                    mentalStatusExam: {
                                                                                        ...formData.mentalStatusExam,
                                                                                        affect: {
                                                                                            ...formData.mentalStatusExam?.affect,
                                                                                            other: e.target.value
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>
                                                THOUGHT FORMATION<br />
                                                <span>
                                                    <input style={{ fontSize: "larger" }}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                    // checked={formData.mentalStatusExam?.thoughtFormation?.u}
                                                    // onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { flat: e.target.checked } } })}
                                                    />
                                                    <label style={{ fontWeight: "400", margin: "0.2rem", marginInlineStart: "0.3rem" }}>Unremarkable</label>
                                                </span>
                                            </td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.logical}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { logical: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Logical</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.illogical}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { illogical: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Illogical</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.sequential}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { sequential: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Sequential</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.indecisive}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { indecisive: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Indecisive</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.goalDirected}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { goalDirected: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Goal Directed</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.concrete}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { concrete: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Concrete</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.relevant}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { relevant: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Relevant</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.irrelevant}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { irrelevant: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Irrelevant</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.distractible}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { distractible: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Distractible</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.flightofIdeas}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { flightofIdeas: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Flight of Ideas.</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.obsessive}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { obsessive: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Obsessive</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.blocking}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { blocking: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Blocking</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.circumstantial}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { circumstantial: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Circumstantial</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.tangential}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { tangential: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Tangential</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.looseAssociations}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { looseAssociations: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Loose Association</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtFormation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtFormation?.unexplainedDizziness}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtFormation: { unexplainedDizziness: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Unexplained dizziness</label>
                                                            </td>
                                                            <td colSpan={3}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Other :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="otherThought"
                                                                                readOnly={!edit}
                                                                                value={formData.mentalStatusExam?.thoughtFormation?.other}
                                                                                onChange={e => setFormData({
                                                                                    ...formData,
                                                                                    mentalStatusExam: {
                                                                                        ...formData.mentalStatusExam,
                                                                                        thoughtFormation: {
                                                                                            ...formData.mentalStatusExam?.thoughtFormation,
                                                                                            other: e.target.value
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>
                                                THOUGHT CONTENT<br />
                                                <span>
                                                    <input style={{ fontSize: "larger" }}
                                                        type="checkbox"
                                                        className="form-check-input"

                                                    />
                                                    <label style={{ fontWeight: "400", margin: "0.2rem", marginInlineStart: "0.3rem" }}>Unremarkable</label>
                                                </span>
                                            </td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.delusions}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { delusions: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Delusions</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.suicidal}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { suicidal: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Suicidal</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.homicidal}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { homicidal: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Homicidal</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.obsessions}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { obsessions: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Obsessions</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.hopelessness}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { hopelessness: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Hopelessness</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.somatic}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { somatic: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Somatic</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.guilt}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { guilt: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Guilt</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.phobias}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { phobias: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Phobias</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.grandiose}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { grandiose: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Grandiose</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.futureOriented}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { futureOriented: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Future Oriented</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.separationLossDwelling}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { separationLossDwelling: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Dwells on issues of separation / loss</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.suspicious}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { suspicious: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Suspicious</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="thoughtContent"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.thoughtContent?.hallucinations}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, thoughtContent: { hallucinations: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Hallucinations</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>ORIENTATION</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="orientation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.orientation?.person}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, orientation: { person: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Person</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="orientation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.orientation?.place}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, orientation: { place: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Place</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="orientation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.orientation?.time}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, orientation: { time: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Time</label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="orientation"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.orientation?.situation}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, orientation: { situation: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Situation</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>ESTIMATED INTELLECT</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="estimatedIntellect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.estimateIntellect?.average}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, estimateIntellect: { average: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Average</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="estimatedIntellect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.estimateIntellect?.upAverage}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, estimateIntellect: { upAverage: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                                                    <span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:arrow-up"></span>
                                                                    Average
                                                                </label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="estimatedIntellect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.estimateIntellect?.downAverage}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, estimateIntellect: { downAverage: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>
                                                                    <span style={{ fontSize: "1.2rem" }} className="iconify" data-icon="uil:arrow-down"></span>
                                                                    Average
                                                                </label>
                                                            </td>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="estimatedIntellect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.estimateIntellect?.borderline}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, estimateIntellect: { borderline: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Borderline</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="estimatedIntellect"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.estimateIntellect?.mentalRetardation}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, estimateIntellect: { mentalRetardation: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Mental Retardation</label>
                                                            </td>

                                                            <td colSpan={3}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Known Disability:
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                readOnly={!edit}
                                                                                id="knownDisability"
                                                                                value={formData.mentalStatusExam?.estimateIntellect?.knownDisability || ""}
                                                                                onChange={e => setFormData(prevState => ({
                                                                                    ...prevState,
                                                                                    mentalStatusExam: {
                                                                                        ...prevState?.mentalStatusExam,
                                                                                        estimateIntellect: {
                                                                                            ...prevState?.mentalStatusExam?.estimateIntellect,
                                                                                            knownDisability: e.target.value
                                                                                        }
                                                                                    }
                                                                                }))}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>MEMORY</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="memory"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.memory?.adequate}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, memory: { adequate: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Adequate</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="memory"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.memory?.impaired}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, memory: { impaired: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Impaired</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="memory"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.memory?.recent}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, memory: { recent: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Recent</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="memory"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.memory?.remote}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, memory: { remote: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Remote</label>
                                                            </td>
                                                            <td>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="memory"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.memory?.selective}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, memory: { selective: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Selective</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>
                                                PERCEPTIONS<br />
                                                <span>
                                                    <input style={{ fontSize: "larger" }}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                    />
                                                    <label style={{ fontWeight: "400", margin: "0.2rem", marginInlineStart: "0.3rem" }}>Unremarkable</label>
                                                </span>
                                            </td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: "38%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="perception"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.perception?.auditoryHallucinations}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, perception: { auditoryHallucinations: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Auditory Hallucinations</label>
                                                            </td>
                                                            <td colSpan={2} style={{ width: "40%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="perception"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.perception?.depersonalization}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, perception: { depersonalization: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Depersonalization</label>
                                                            </td>
                                                            <td style={{ width: "22%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="perception"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.perception?.ideasofReference}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, perception: { ideasofReference: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Ideas of Ref.</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2} style={{ width: "38%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="perception"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.perception?.visualHallucinations}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, perception: { visualHallucinations: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Visual Hallucinations</label>
                                                            </td>
                                                            <td colSpan={3}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="perception"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.perception?.traumaticFlashbacks}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, perception: { traumaticFlashbacks: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Traumatic Flashbacks</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>JUDGMENT / INSIGHT</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="judgment"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.judgment?.poor}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, judgment: { poor: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Poor</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="judgment"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.judgment?.fair}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, judgment: { fair: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Fair</label>
                                                            </td>
                                                            <td colSpan={3} style={{ width: "62%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="judgment"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.judgment?.good}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, judgment: { good: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Good</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5}>
                                                                <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.2rem" }}>
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <h6 className="fw-500" style={{ margin: "0.3rem" }}>
                                                                                Impaired (explain) :
                                                                            </h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <input
                                                                                style={{
                                                                                    width: "100%",
                                                                                    border: "none",
                                                                                    marginTop: "0.1rem",
                                                                                    backgroundColor: "unset",
                                                                                }}
                                                                                type="text"
                                                                                id="impaired"
                                                                                readOnly={!edit}
                                                                                value={formData.mentalStatusExam?.judgment?.impaired}
                                                                                onChange={e => setFormData(prevState => ({
                                                                                    ...prevState,
                                                                                    mentalStatusExam: {
                                                                                        ...prevState?.mentalStatusExam,
                                                                                        judgment: {
                                                                                            ...prevState?.mentalStatusExam?.judgment,
                                                                                            impaired: e.target.value
                                                                                        }
                                                                                    }
                                                                                }))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fw-500" colSpan={2} style={{ padding: "0.2rem" }}>IMPULSE CONTROL ATTENTION/CONCENTRATION</td>
                                            <td style={{ padding: "0.2rem" }}>
                                                <Table className="tableTwo">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.fair}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, impulseControl: { fair: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Fair</label>
                                                            </td>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.poor}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, impulseControl: { poor: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Poor</label>
                                                            </td>
                                                            <td style={{ width: "20%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.severe}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, impulseControl: { severe: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Severe</label>
                                                            </td>
                                                            <td colSpan={2} style={{ width: "42%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.adequate}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, impulseControl: { adequate: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Adequate / Age Appropriate</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.wNL}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, impulseControl: { wNL: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>WNL</label>
                                                            </td>
                                                            <td colSpan={2} style={{ width: "19%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.mildlydistractible}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, impulseControl: { mildlydistractible: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Mildly distractible</label>
                                                            </td>

                                                            <td colSpan={2} style={{ width: "42%" }}>
                                                                <input style={{ fontSize: "larger" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="impulseControl"
                                                                    disabled={!edit}
                                                                    defaultChecked={formData.mentalStatusExam?.impulseControl?.moderately}
                                                                    onChange={e => setFormData({ ...formData, mentalStatusExam: { ...formData.mentalStatusExam, impulseControl: { moderately: e.target.checked } } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Moderately Distractible</label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <div className="card-bodyDiv">
                        <Card className="match-card-header p-2 mb-2">
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#ffeee1', padding: '10px', borderRadius: '5px' }}>
                            <h5 className="fw-500" style={{ margin: 'auto', marginRight: '10%' }}>Suicide / Homicide Risk Potential</h5>
                            <div className="form-check" style={{ position: 'relative', zIndex: 1000 }}>
                                <input 
                                    id="noRiskFactorsCheckbox"
                                    className="form-check-input" 
                                    type="checkbox" 
                                    style={{ 
                                        width: "24px", 
                                        height: "24px", 
                                        cursor: "pointer",
                                        position: "relative",
                                        zIndex: 100,
                                        margin: "0 10px 0 0"
                                    }} 
                                    checked={formData.suicideRiskPotential?.noRiskfactorsIdentified || false}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const isChecked = !formData.suicideRiskPotential?.noRiskfactorsIdentified;
                                        
                                        const newData = {...formData};
                                        
                                        if (!newData.suicideRiskPotential) {
                                            newData.suicideRiskPotential = {};
                                        }
                                        
                                        newData.suicideRiskPotential.noRiskfactorsIdentified = isChecked;
                                        
                                        console.log("Checkbox được click - Giá trị mới:", isChecked);
                                        
                                        setFormData(newData);
                                    }}
                                />
                                <label 
                                    className="form-check-label" 
                                    style={{ 
                                        fontStyle: "italic", 
                                        cursor: "pointer", 
                                        position: "relative",
                                        zIndex: 99,
                                        fontSize: "16px",
                                        userSelect: "none"
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        document.getElementById('noRiskFactorsCheckbox')?.click();
                                    }}
                                >
                                    Check if no risk factors identified
                                </label>
                            </div>
                        </div>
                        </Card>
                        <Card className="match-card-body p-3">
                            <div style={{ overflowX: "auto" }}>
                                <Table className="clinical-table-bordered table-sm" style={{ minWidth: "600px" }}>
                                    <tbody style={{ verticalAlign: "middle" }}>
                                        <tr>
                                            <td colSpan={3} className="fw-600" style={{ padding: "0.2rem" }}>
                                                Suicide Attempts
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-600" style={{ width: "33%", padding: "0.2rem" }}>Action / Method / Date (year)</td>
                                            <td className="fw-600" style={{ width: "34%", padding: "0.2rem" }}>Ideation</td>
                                            <td className="fw-600" style={{ width: "33%", padding: "0.2rem" }}>Plan</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="sYear"
                                                        readOnly={!edit}
                                                        value={formData.suicideRiskPotential?.suicideAttempts?.actionMethod}
                                                        onChange={e => setFormData(prevState => ({
                                                            ...prevState,
                                                            suicideRiskPotential: {
                                                                ...prevState.suicideRiskPotential,
                                                                suicideAttempts: {
                                                                    ...prevState.suicideRiskPotential?.suicideAttempts,
                                                                    actionMethod: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="sIdeation"
                                                        readOnly={!edit}
                                                        value={formData.suicideRiskPotential?.suicideAttempts?.Ideation}
                                                        onChange={e => setFormData(prevState => ({
                                                            ...prevState,
                                                            suicideRiskPotential: {
                                                                ...prevState.suicideRiskPotential,
                                                                suicideAttempts: {
                                                                    ...prevState.suicideRiskPotential?.suicideAttempts,
                                                                    Ideation: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="sPalne"
                                                        readOnly={!edit}
                                                        value={formData.suicideRiskPotential?.suicideAttempts?.plan}
                                                        onChange={e => setFormData(prevState => ({
                                                            ...prevState,
                                                            suicideRiskPotential: {
                                                                ...prevState.suicideRiskPotential,
                                                                suicideAttempts: {
                                                                    ...prevState.suicideRiskPotential?.suicideAttempts,
                                                                    plan: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} className="fw-600" style={{ padding: "0.2rem" }}>
                                                Homicide Attempts
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-600" style={{ width: "33%", padding: "0.2rem" }}>Action / Method / Date (year)</td>
                                            <td className="fw-600" style={{ width: "34%", padding: "0.2rem" }}>Ideation</td>
                                            <td className="fw-600" style={{ width: "33%", padding: "0.2rem" }}>Plan</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="hYear"
                                                        readOnly={!edit}
                                                        value={formData.suicideRiskPotential?.homicideAttempts?.actionMethod}
                                                        onChange={e => setFormData(prevState => ({
                                                            ...prevState,
                                                            suicideRiskPotential: {
                                                                ...prevState.suicideRiskPotential,
                                                                homicideAttempts: {
                                                                    ...prevState.suicideRiskPotential?.homicideAttempts,
                                                                    actionMethod: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="hIdeation"
                                                        readOnly={!edit}
                                                        value={formData.suicideRiskPotential?.homicideAttempts?.Ideation}
                                                        onChange={e => setFormData(prevState => ({
                                                            ...prevState,
                                                            suicideRiskPotential: {
                                                                ...prevState.suicideRiskPotential,
                                                                homicideAttempts: {
                                                                    ...prevState.suicideRiskPotential?.homicideAttempts,
                                                                    Ideation: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="col-auto">
                                                    <input style={{ width: "100%", border: "none", backgroundColor: "unset", textAlign: "center" }}
                                                        type="text"
                                                        id="hPalne"
                                                        readOnly={!edit}
                                                        value={formData.suicideRiskPotential?.homicideAttempts?.plan}
                                                        onChange={e => setFormData(prevState => ({
                                                            ...prevState,
                                                            suicideRiskPotential: {
                                                                ...prevState.suicideRiskPotential,
                                                                homicideAttempts: {
                                                                    ...prevState.suicideRiskPotential?.homicideAttempts,
                                                                    plan: e.target.value
                                                                }
                                                            }
                                                        }))}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ textAlign: "left" }}>
                                            <td colSpan={3} className="fw-600" style={{ padding: "0.2rem" }}>
                                                <Row>
                                                    <Col>
                                                        <div className="row">
                                                            <div className="col-md-7 col-xs-12">
                                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is there a history of impulsive behaviors that present a danger to self/ others?</h6>
                                                            </div>

                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="historyOfImpulsiveBehaviors"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.historyOfImpulsiveBehaviors === true}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, historyOfImpulsiveBehaviors: true } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Yes</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    name="historyOfImpulsiveBehaviors"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.historyOfImpulsiveBehaviors === false}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, historyOfImpulsiveBehaviors: false } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="row">
                                                            <div className="col-md-7 col-xs-12">
                                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Is consumer in a safe environment?</h6>
                                                            </div>

                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="safeEnviornment"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.safeEnviornment === true}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, safeEnviornment: true } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Yes</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="safeEnviornment"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.safeEnviornment === false}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, safeEnviornment: false } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>No</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="row">
                                                            <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of cruelty to animals?</h6>
                                                            </div>

                                                            <div className="col-auto form-check form-check-inline">
                                                                <input className="form-check-input"
                                                                    type="radio" style={{ fontSize: "larger" }}
                                                                    name="crueltytoAnimalsHistory"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.crueltytoAnimalsHistory === true}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, crueltytoAnimalsHistory: true } })}
                                                                />
                                                                <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Yes</label>
                                                            </div>
                                                            <div className="col-auto form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                                    name="crueltytoAnimalsHistory"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.crueltytoAnimalsHistory === false}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, crueltytoAnimalsHistory: false } })}
                                                                />
                                                                <label className="form-check-label" style={{ marginTop: "0.2rem" }}>No</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="row">
                                                            <div className="col-auto" style={{ marginBottom: "0.5rem" }}>
                                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>History of fire-setting?</h6>
                                                            </div>

                                                            <div className="col-auto form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                                    name="fireSetting"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.fireSetting === true}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, fireSetting: true } })}
                                                                />
                                                                <label className="form-check-label" style={{ marginTop: "0.2rem" }}>Yes</label>
                                                            </div>
                                                            <div className="col-auto form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" style={{ fontSize: "larger" }}
                                                                    name="fireSetting"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.fireSetting === false}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, fireSetting: false } })}
                                                                />
                                                                <label className="form-check-label" style={{ marginTop: "0.2rem" }}>No</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Other risk factors (i.e. alcohol or drug use, self-injury, etc) :</h6>
                                                                </div>
                                                                <div className="col">
                                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                        type="text"
                                                                        className="fw-500"
                                                                        id="otherRisk"
                                                                        readOnly={!edit}
                                                                        value={formData.suicideRiskPotential?.otherRiskFactors}
                                                                        onChange={e => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, otherRiskFactors: e.target.value } })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="row">
                                                            <div className="col-xl-auto col-md-12">
                                                                <h6 className="fw-500" style={{ margin: "0.2rem" }}>Lethality Assessment :</h6>
                                                            </div>

                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="lethalityAssessment"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.lethalityAssessment === lethalityAssessment.NONE}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, lethalityAssessment: lethalityAssessment.NONE as lethalityAssessment } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>None</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="lethalityAssessment"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.lethalityAssessment === lethalityAssessment.LOW}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, lethalityAssessment: lethalityAssessment.LOW as lethalityAssessment } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Low</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="lethalityAssessment"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.lethalityAssessment === lethalityAssessment.MODERATE}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, lethalityAssessment: lethalityAssessment.MODERATE as lethalityAssessment } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Moderate</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="lethalityAssessment"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.lethalityAssessment === lethalityAssessment.HIGH}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, lethalityAssessment: lethalityAssessment.HIGH as lethalityAssessment } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>High</label>
                                                            </div>
                                                            <div className="col-auto">
                                                                <input style={{ fontSize: "larger", marginRight: "0.5rem" }}
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    disabled={!edit}
                                                                    checked={formData.suicideRiskPotential?.lethalityAssessment === lethalityAssessment.IMMINENT}
                                                                    onClick={() => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, lethalityAssessment: lethalityAssessment.IMMINENT as lethalityAssessment } })}
                                                                />
                                                                <label className="fw-500" style={{ margin: "0.2rem", marginInlineStart: "0.3rem" }}>Imminent -  If Moderate, High, or Imminent, attach signed Safety Plan.</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Card style={{ backgroundColor: "#F1F1F1", marginBottom: "0.5rem" }}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <h6 className="fw-500" style={{ margin: "0.3rem" }}>Additional Comments :</h6>
                                                                </div>
                                                                <div className="col">
                                                                    <input style={{ width: "100%", border: "none", marginTop: "0.1rem", backgroundColor: "unset" }}
                                                                        type="text"
                                                                        id="comments"
                                                                        className="fw-500"
                                                                        readOnly={!edit}
                                                                        value={formData.suicideRiskPotential?.addtionalComments}
                                                                        onChange={e => setFormData({ ...formData, suicideRiskPotential: { ...formData.suicideRiskPotential, addtionalComments: e.target.value } })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                </Row>

                <Row>
                    <Col xs={4}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                            }}
                            onClick={() => {
                                props.previousStep();
                            }}
                        >
                            <i className="mr-1 mt-1 fas fa-regular fa-arrow-left "></i>
                            Back
                        </button>
                    </Col>
                    <Col xs={8}>
                        <button
                            type="button"
                            className="btn btn-skip  relative mt-3 mr-2 mb-4"
                            style={{
                                bottom: "0",
                                float: "right",
                            }}
                            onClick={props.nextStep}
                        >
                            Next
                        </button>
                        {edit &&
                            <button
                                type="button"
                                className="btn btn-primary mr-2 relative mt-3 mb-2"
                                style={{
                                    bottom: "0",
                                    float: "right",
                                }}
                                onClick={() => {
                                    updateAssesmentDetails();
                                }}
                            >
                                {props.totalSteps > 17 ? "Next" : "Save & Continue"}
                            </button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                            <h6 className="fw-500 mb-3" style={{ fontFamily: "Federo" }}>Page 7 of 16</h6>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default FormSeven;